export const DATATYPE = {
  SUMMARY: "summary",
  PATIENT: "patient",
  DISEASE: "disease",
  RELEASES: "releases",
  TOTALS: "totals"
};

export const fetchSummaryData = (axiosInstance, summary, resolve, reject) => {
  axiosInstance
    .get("/")
    .then(response => {
      resolve(response.data);
    })
    .catch(error => {
      console.error("Failed to fetch summary information", error);
      reject({});
    });
};

export const fetchPaginatedDetailData = ({
  axiosInstance,
  dataType, // patient, disease, releases
  initialData,
  maxPageIndex,
  currentPageIndex,
  resolve,
  reject
}) => {
  axiosInstance
    .get(`/${dataType}/${currentPageIndex}`)
    .then(response => {
      //console.log(`Inside of fetchData() DataType: [${dataType}] - Page: [${currentPageIndex}/${maxPageIndex}]-  [${response.data.details.length}] record(s).`);
      const retrievedData = initialData.concat(response.data.details);

      if (currentPageIndex < maxPageIndex) {
        fetchPaginatedDetailData({
          axiosInstance,
          dataType,
          initialData: retrievedData,
          maxPageIndex,
          currentPageIndex: currentPageIndex + 1,
          resolve,
          reject
        });
      } else {
        resolve(retrievedData);
      }
    })
    .catch(error => {
      console.error(error);
      reject([]);
    });
};
